import React, { useState, useEffect } from "react";
import { CheckIcon, XCircleIcon } from '@heroicons/react/outline'


const Spinner = () => (
    
  <svg className="animate-spin h-20 w-20 mr-3"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 80 80"
  style={{
    enableBackground: "new 0 0 80 80",
  }}
  xmlSpace="preserve"
>
  <path
    d="M-93.5-3.4v25c0 3.3-2.6 5.9-5.9 5.9h-39.7c-3.3 0-5.9-2.6-5.9-5.9v-38.5c0-3.3 2.6-5.9 5.9-5.9h5.5v-4.4h4.5v11.1h3.4v-6.8h14.6v-4.4h4.5v11.1h3.4V-23h3.7c3.3 0 5.9 2.6 5.9 5.9v5.1l-26.5 18.5-10.3-8.2-4.4 5.1 14.6 11.4 26.7-18.2z"
    style={{
      fill: "#001447",
    }}
    transform="translate(160 40.467)"
  />
</svg>
)

export default function App() {

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const getData = async (URL) => {

    const response = await fetch(URL, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    return (response.json());
  }

  // Process
  // Loading is true until data is retrieved from the web server
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  const [businessName, setBusinessName] = useState("");
  const [clientFirstName, setClientFirstName] = useState("");
  const [customerFirstName, setCustomerFirstName] = useState("");

  useEffect(() => {
    // TODO: Get our UUID from the query inputs
    const queryParams = new URLSearchParams(window.location.search)
    const getId = queryParams.get("id")
    
    let URL = `${serverUrl}/emailverify?id=${getId}`

    if (getId !== "" && getId !== undefined) {
      getData(URL).then(res => {
        // console.log(res)
        if ((res.businessName??"") && res.businessName !== "") {
          setBusinessName(res.businessName)
          setLoading(false)
          setVerified(true)
        } else {
          setLoading(false)
          setVerified(false)
        }

        if ((res.clientFirstName??"") && res.clientFirstName !== "")
          setClientFirstName(res.clientFirstName)
        if ((res.customerFirstName??"") && res.customerFirstName !== "")
          setCustomerFirstName(res.customerFirstName)
      }
      )
    }

  },[serverUrl])


  return (
      
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 mainbackground">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-20 w-auto"
            src="/colour_logo.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Email Verification</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form>
              {loading? 
              <>
                <div className="spinner">
                  <Spinner/>
                </div>
                <div>

                <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Loading</h2>
                </div>
                
              </>
              :
              <div>
              {verified?
              <>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>

                <h4 className="font-bold">Hello {customerFirstName},</h4>
                <br/>
                <p>Thank you, your email is now verified with {businessName}.</p>
                <br/>
                <p>We will send you appointment reminders and the opportunity to cancel appointments without fuss.</p>
                <br/>
                <p>You can close this page now.</p>
                <br/>
                <p>Kind regards,</p>
                <p className="font-bold">{clientFirstName},</p>
                <p className="font-bold">@ {businessName}</p>
              </>
              :
              <>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <br/>
              <h4 className="font-bold text-center">Not Found</h4>
              <br/>
              <p className="text-center">Email verification links are one time only and last for 24 hours.</p>
              </>
            }

            </div>
              }

            </form>

            <div className="mt-6">
              <div className="relative">
                {verified? <>
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">{businessName} is a customer of <a href="https://bookingApp.net">BookingApp.net</a></span>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">To unsubscribe, please contact {businessName}</span>
                  </div>
                  
                  </>
                  : null 
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  
  )
}

